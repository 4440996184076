import { graphql, navigate, useStaticQuery } from 'gatsby'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Button } from 'nzk-react-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import getGatsbyImage from 'utils/getGatsbyImage'
import { formatAmount } from 'components/Strapi/StrapiPlanHero'
// @ts-ignore
import { useShoppingCart } from "use-shopping-cart"
import { useShopLocaleState } from '../../../../../providers/ShopLocaleProvider'
import { formatProductForCountry } from "../../../../../hooks/useStrapiShopProduct"

const Wrapper = styled.div`
  min-height: calc(100vh - 400px);
  padding-top: 20px;
`

const Title = styled.div`
  font-size: 2em;
  text-align: center;
  color: #701EA8;
  font-family: 'Rammetto One';
`

const Prices = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 15px;
  > * {
    margin-right: 20px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const Price = styled.div`
  background-color: #701EA8;
  box-shadow: 0 -4px 0 #9026C1, 0 5px 0 #4E1B6D, 0 10px 0 rgba(0, 0, 0, 0.3);
  color: #fff;
  padding: 20px;
  border-radius: 20px;
  max-width: 380px;
  > .title {
    font-size: 1.4em;
    margin-bottom: 15px;
    text-align: center;
  }
  > .description {
    margin-top: 10px;
    line-height: 26px;
    text-align: center;
  }
  > .image {
  }
  > .price {
    margin-top: 20px;
    font-size: 2em;
    text-align: center;
  }
  > .action {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
`

interface IProductData {
  allStrapiCountries: {
    edges: {
      node: {
        locale: string
        name: string
        currencyCode: string
        conversionRateToUSD: number
      }
    }[]
  }
  strapiShopProducts: {
    id: string
    slug: string
    name: string
    thumbnail: {
      url: string
    }
    digital: string
    skus: {
      id: string
      sku: string
      title: string | null
      description: string | null
      warehouse: string
      thumbnail: {
        url: string
      } | null
      pricings: {
        slug: string
        shippingRate: string
        countries: {
          currencyCode: string
          code: string
          locale: string
          conversionRateToUSD: number | null
        }[]
        currency: string
        amount: number
      }[]
    }[]
  }
}

const SubscriptionGift = () => {
  const { locale } = useShopLocaleState()
  const { addItem } = useShoppingCart()
  const data: IProductData = useStaticQuery(graphql`
    {
      allStrapiCountries {
        edges {
          node {
            locale
            name
            currencyCode
            conversionRateToUSD
          }
        }
      }
      strapiShopProducts(
        slug: { eq: "subscription-gift-voucher" }
      ) {
        id
        slug
        name
        thumbnail {
          url
        }
        digital
        skus {
          id
          sku
          title
          shortDescription
          warehouse
          thumbnail {
            url
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          pricings {
            slug
            shippingRate
            countries {
              currencyCode
              code
              locale
              conversionRateToUSD
            }
            currency
            amount
          }
        }
      }
    }
  `)

  const { node: country } = data.allStrapiCountries.edges.find(
    ({ node }) => node.locale === locale
  ) as { node: Strapi.Country }


  const product = useMemo(() => {
    if (data?.strapiShopProducts && country) {
      // @ts-ignore
      const prod = formatProductForCountry(data.strapiShopProducts, country)
      return prod
    }
  }, [data, country])

  const onAddToBasket = (sku: Strapi.ShopProductSKU) => {
    if (!product) return
    addItem(
      {
        id: sku.sku,
        sku: sku.sku,
        price: sku.pricings[0].amount,
        currency: sku.pricings[0].currency.toUpperCase(),
        name: product.name,
        description: sku.shortDescription,
        shortDescription: sku.shortDescription,
        image: sku.thumbnail?.url || product.thumbnail?.url,
        metadata: {
          countryCodes: sku.pricings[0].countries.map(c => c.code),
          warehouse: sku.warehouse,
          digital: product.digital,
          personalisedOptions: {
          },
        },
      },
      { count: 1 }
    )
    setTimeout(() => {
      navigate("/shop/checkout")
    }, 200)
  }

  return <Wrapper>
    <Title>Choose an option</Title>
    <Prices>
    {
      product?.skus
        .sort((a, b) => a.pricings[0].amount > b.pricings[0].amount ? 1 : -1)
        .map(sku => {
        return <Price key={sku.id}>
          <div className='title'>{sku.title || sku.sku}</div>
          { sku.thumbnail && <div className='image'>
            <GatsbyImage image={getGatsbyImage(sku.thumbnail.localFile)} alt={sku.thumbnail.alternativeText} />
          </div> }
          <div className='description'>{sku.shortDescription || 'No description'}</div>
          { /* @ts-ignore */ }
          <div className='price'>{sku.pricings[0].readableAmount
            || formatAmount(country.currencyCode, sku.pricings[0].amount)}</div>
          <div className='action'>
            <Button size='small' theme='primary' onClick={() => {
              onAddToBasket(sku)
            }}>Add to Basket</Button>
          </div>
        </Price>
      })
    }
    </Prices>
  </Wrapper>
}

export default SubscriptionGift
