import * as React from "react"
import SubscriptionGift from "./components/SubscriptionGift"

const IndexPage = () => {
  return (
    <>
      <SubscriptionGift />
    </>
  )
}

export default IndexPage
