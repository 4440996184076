import React from "react"
import styled from "styled-components"
import ShopHeader from "components/ShopHeader"
import SubscriptionGift from 'components/SubscriptionGiftProduct/SubscriptionGift'
import ShopLayout from "../../../../../layouts/ShopLayout"

const Wrapper = styled.div`
  > :first-child {
    padding-top: 50px;
  }
`

export default () => {
  return (
    <>
      <ShopLayout>
        <Wrapper>
          <ShopHeader />
          <SubscriptionGift />
        </Wrapper>
      </ShopLayout>
    </>
  )
}
